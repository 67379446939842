import { AsnFilterTypes, AsnFilters } from './models'
import moment from 'moment'
import Accumulator from '../filters/components/accumulator'
import React from 'react'
import { Paper } from '@material-ui/core'
import { state as asnFilter } from './components/asnFilters'
import { state as filterState } from '../filters/components/filters'

import { AdvancedShippingNoticeView } from '../../../advancedShippingNotice/models'
import api from '../../api'
import filtersState from '../filters/filterGlobalState'
import asnGlobalFiltersState from './asnGlobalFiltersState'
import { t } from 'i18next'
import filterHelper from '../filters/filterHelper'
import { FilterAsn, FiltersPost } from '../../../ordreDeFabrication/models'
import authToken from '../../authToken'
let loadCompleteDataSetRunning: boolean = false

let loadFilteredDataSetInner = async (body: FilterAsn, pageNumber: Number) => {
    const userProfile = authToken.getAuthProfile()
    let fabricant: any
    if (userProfile) fabricant = userProfile.fabricantCode

    if (!fabricant || fabricant == 'undefined') {
        fabricant = 'none'
        let a: any
        let b: any
        ;[a, b] = await Promise.all([
            api.post<any>(`asn/filters/${pageNumber}/${fabricant}`, {}),
            api.post<any>(`asn/filters/${pageNumber}/${fabricant}`, body),
        ])
        asnGlobalFiltersState.advancedShippingNoticeInitial = a.asn
        asnGlobalFiltersState.advancedShippingNoticeToDisplay = b.asn
    } else {
        let asn = await api.post<any>(`asn/filters/${pageNumber}/${fabricant}`, body)
        asnGlobalFiltersState.advancedShippingNoticeInitial = asn.asn
        asnGlobalFiltersState.advancedShippingNoticeToDisplay = asnGlobalFiltersState.advancedShippingNoticeInitial
    }
}

export let loadCompleteDataSetAsn = async (pageNumber: number = 1) => {
    if (loadCompleteDataSetRunning) {
        return
    } else {
        try {
            loadCompleteDataSetRunning = true
            let result = filterHelper.filtersFlat(filterState.appliedFilters)
            let resultAsn = filterHelper.filtersFlat(asnFilter.appliedFilters)

            let body: FilterAsn = {
                ManufacturerCode: [],
                ShopCode: [],
                NumberAsn: [],
                NumberWo: [],
                SeasonCode: [],
                TypeWO: [],
                Group: [],
                StatusWo: [],
                SubActivityCode: [],
                TypeCode: [],
                Gender: [],
                ModelCode: [],
                MaterialCode: [],
                ColorCode: [],
                Status: [],
                ShippingDateFrom: '',
                ShippingDateTo: '',
            }
            result.forEach(x => {
                switch (x.type) {
                    case 'color':
                        body.ColorCode.push(x.key)
                        break
                    case 'manufacturer':
                        body.ManufacturerCode.push(x.key)
                        break
                    case 'shop':
                        body.ShopCode.push(x.key)
                        break
                    case 'season':
                        body.SeasonCode.push(x.key)
                        break
                    case 'typeWO':
                        body.TypeWO.push(x.key)
                        break
                    case 'group':
                        body.Group.push(x.key)
                        break
                    case 'status':
                        body.StatusWo.push(x.key)
                        break
                    case '#WO':
                        body.NumberWo.push(x.key)
                        break
                    case 'subActivity':
                        body.SubActivityCode.push(x.key)
                        break
                    case 'type':
                        body.TypeCode.push(x.key)
                        break
                    case 'gender':
                        body.Gender.push(x.key)
                        break
                    case 'model':
                        body.ModelCode.push(x.key)
                        break
                    case 'material':
                        body.MaterialCode.push(x.key)
                        break
                }
            })
            resultAsn.forEach(x => {
                switch (x.type) {
                    case 'Status':
                        if (x.key === 'Not Received' || x.key === 'Non reçu' || x.key === 'Non ricevuto')
                            x.key = 'NonRecu'
                        else if (
                            x.key === 'Completely received' ||
                            x.key === 'Complètement reçu' ||
                            x.key === 'Completamente ricevuto'
                        )
                            x.key = 'CompletementRecu'
                        else if (
                            x.key === 'In progress' ||
                            x.key === 'En cours' ||
                            x.key === 'In corso'
                        )
                            x.key = 'EnCours'
                        else {
                            x.key = 'PartiellementRecu'
                        }
                        body.Status.push(x.key)
                        break

                    case 'NumberAsn':
                        body.NumberAsn.push(x.key)
                        break

                    case 'ShippingDateFrom':
                        body.ShippingDateFrom = x.key
                        break

                    case 'ShippingDateTo':
                        body.ShippingDateTo = x.key
                }
            })
            await loadFilteredDataSetInner(body, pageNumber)
        } finally {
            loadCompleteDataSetRunning = false
        }
    }
}

export let getPropertyNameFromFilterType = (type: AsnFilterTypes) => {
    switch (type) {
        case AsnFilterTypes.dateExpeditionInf:
            return 'dateExpeditionInf'
        case AsnFilterTypes.dateExpeditionSup:
            return 'dateExpeditionSup'
        case AsnFilterTypes.numeroAsn:
            return 'numeroAsn'
        case AsnFilterTypes.statut:
            return 'statut'
        case AsnFilterTypes.fabricant:
            return 'fabricant'
    }
}

let propDateInf = (): string => getPropertyNameFromFilterType(AsnFilterTypes.dateExpeditionInf)
let propDateSup = (): string => getPropertyNameFromFilterType(AsnFilterTypes.dateExpeditionSup)
let propNumeroAsn = (): string => getPropertyNameFromFilterType(AsnFilterTypes.numeroAsn)
let propStatut = (): string => getPropertyNameFromFilterType(AsnFilterTypes.statut)
let propFabricant = (): string => getPropertyNameFromFilterType(AsnFilterTypes.fabricant)

let initAsnFilters = (asnFilters: AsnFilters, typeToIgnore?: AsnFilterTypes) => {
    let propToIgnore = !!typeToIgnore ? getPropertyNameFromFilterType(typeToIgnore) : ''
    let dateSup = moment(new Date(2000, 1, 1))
    let dateInf = moment(new Date(2099, 11, 31))
    if (propToIgnore != propNumeroAsn()) asnFilters.numeroAsn = {}
    asnFilters.dateExpeditionInf = { key: dateInf.toDate(), value: dateInf.locale() }
    asnFilters.dateExpeditionSup = { key: dateSup.toDate(), value: dateSup.locale() }
    if (propToIgnore != propStatut()) asnFilters.statut = {}
    if (propToIgnore != propFabricant()) asnFilters.fabricant = {}

    return asnFilters
}

let initFilters = (source: AdvancedShippingNoticeView[], baseFilterData: AsnFilters) => {
    let filterData = baseFilterData

    let filterFields: { [field: string]: { [code: string]: string } } = {}
    let dateFilterFields: { [field: string]: { key: Date; value: string } } = {}

    filterFields[propNumeroAsn()] = {}
    filterFields[propStatut()] = {}
    filterFields[propFabricant()] = {}

    dateFilterFields[propDateInf()] = { key: new Date(2099, 12), value: '' }
    dateFilterFields[propDateSup()] = { key: new Date(1901, 1), value: '' }

    for (let i = 0; i < source.length; i++) {
        let asn = source[i]
        let asnCode = asn.numero.pad(8).toString()
        if (!(asnCode in filterFields[propNumeroAsn()])) filterFields[propNumeroAsn()][asnCode] = asnCode

        if (!(asn.statut in filterFields[propStatut()]))
            filterFields[propStatut()][asn.statut] = t('asn.statut.'.concat(asn.statut.toLowerFirstChar()))

        if (!(asn.fabricant.label in filterFields[propFabricant()]))
            filterFields[propFabricant()][asn.fabricant.label] = t(
                'asn.fabricant'.concat(asn.fabricant.label.toLowerFirstChar()),
            )

        if (moment(dateFilterFields[propDateInf()].key).isAfter(moment(asn.dateExpedition)))
            dateFilterFields[propDateInf()] = {
                key: moment(asn.dateExpedition).toDate(),
                value: moment(asn.dateExpedition)
                    .format(t('date.format.date'))
                    .toString(),
            }

        if (moment(dateFilterFields[propDateSup()].key).isBefore(moment(asn.dateExpedition)))
            dateFilterFields[propDateSup()] = {
                key: moment(asn.dateExpedition).toDate(),
                value: moment(asn.dateExpedition)
                    .format(t('date.format.date'))
                    .toString(),
            }
    }

    for (let i in filterFields) {
        let field: { [code: string]: string } = filterFields[i]
        filterData[i] = field
    }

    filterData.dateExpeditionInf = dateFilterFields['dateExpeditionInf']
    filterData.dateExpeditionSup = dateFilterFields['dateExpeditionSup']

    asnGlobalFiltersState.filtersData = filterData
}

let emptyAllExcept = (filters: AsnFilters, type: AsnFilterTypes) => {
    initAsnFilters(filters, type)
}

let filtersFlat = (filters: { [type: string]: { [keys: string]: number } }): { type: string; key: string }[] => {
    let types = Object.keys(filters)
    let result: { type: string; key: string }[] = []
    types.forEach(x => Object.keys(filters[x]).forEach(k => result.push({ type: x, key: k })))

    return result
}

let asnLightFiltersAccumulator = (): JSX.Element => (
    <Paper>
        <Accumulator
            handleDeleteFilter={undefined}
            filtersAccumulated={filtersFlat(asnFilter.appliedFilters)}
            getLabelForKey={getLabelValue}
        />
    </Paper>
)

let getLabelValue = (type: string, key: string): string => {
    let prop = getPropertyNameFromFilterType(type as AsnFilterTypes)

    let result = asnGlobalFiltersState.filtersData[prop][key]

    return !!result ? result : key
}

let asnFiltersCanLoad = () => filtersState.ordreDeFabricationsToDisplay.length > 0

export default {
    getPropertyNameFromFilterType,
    initFilters,
    initAsnFilters,
    emptyAllExcept,
    getLabelValue,
    filtersFlat,
    asnLightFiltersAccumulator,
    asnFiltersCanLoad,
}
