import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { Security, LoginCallback, useOktaAuth } from '@okta/okta-react'
import { Route, Routes, useNavigate , Outlet, useLocation, Navigate} from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import AsnDraft from '../advancedShippingNotice/components/draft'
import { Wrapped as DetailsOfSkuWrapped} from '../ordreDeFabrication/components/detailsOfSku/Wrapped'
import ListeOrdresDeFabrication from '../ordreDeFabrication/components/liste/listeOrdresDeFabrication'
import PlanningProduit from '../ordreDeFabrication/components/planningParProduit/planningProduit'
import ChangeLanguage from '../common/changeLanguage'
import OktaAuth, {  toRelativeUrl } from '@okta/okta-auth-js'
import {callback, oktaConfig} from '../../config/oktaConfig';
import Shell from './shell/shell'
import api from './api';
import { Profile } from './signIn/models';
import { getAuthProfile, setAuthProfile, setAuthToken, GroupTypes } from './authToken'
import { Wrapped as ListAsnWrapped , WrappedDetails as AsnDetailsWrapped} from '../advancedShippingNotice/components/Wrapped'
import ErrorProfile from '../common/ErrorProfile';
import {state as notificationsState} from './notifications';
import Snackbar from './snackbar';

export const SecureRoutes = () => {

  const oktaClient = useRef(new OktaAuth(oktaConfig))

  const navigate = useNavigate()
  const restoreOriginalUri = (_: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  const protectedRoute = (profile: Profile | null) => {
    if (profile?.group !== GroupTypes.readOnly) {
      return (
        <>
          <Route path="/newAsn" element={<AsnDraft />} />
          <Route path="/planningProduit" element={<PlanningProduit />} />
        </>
      )
    }else {
      return null
    }
  }

  return (
    <Security oktaAuth={oktaClient.current} restoreOriginalUri={restoreOriginalUri}>
            <Routes>
              <Route path={`/${callback}`} element={<LoginCallback />} />
              <Route path="/error" element={<ErrorProfile />} />
              <Route path="/" element={<RequiredAuthenticatedApp  />}>
                <Route index element={<ListeOrdresDeFabrication />} />
                <Route path="/changeLanguage" element={<ChangeLanguage />} />
                <Route path="/listAsn" element={<ListAsnWrapped />} />
                <Route path="/detailsAsn/:numero/" element={<AsnDetailsWrapped />}  />
                <Route path="/detailsOfSku/:numero/:produitCode/" element={<DetailsOfSkuWrapped />} />
                {protectedRoute(getAuthProfile())}
                <Route path="*" element={<Navigate to="/" />} />
              </Route>
            </Routes>
        </Security>
    )
}

interface IParams {
  children: React.ReactNode;
}

interface IUser {
  token: string,
  profile: Profile
}

interface IAuthResponse<T> {
  data?: { value: T },
  message: string,
  success: boolean
}

export const RequiredAuthenticatedApp = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [token, setToken] = useState<string | undefined>(undefined)
  const [user, setUser] = useState<Profile | undefined>(undefined)
  const [isProfileLoading, setIsProfileLoading] = useState(false)
  const [isPorfileSuccess, setIsProfileSuccess] = useState(false)
  const [profileErrorMessage, setProfileErrorMessage ] = useState<string | undefined>(undefined)

    const isAuthenticated = authState?.isAuthenticated;
    const isAuthenticationPending = authState === null;

    const login = () => {
        const originalUri = toRelativeUrl(window.location.href, window.location.origin);
        oktaAuth.setOriginalUri(originalUri);
        oktaAuth.signInWithRedirect();
    };

  const getProfile = useCallback(async () => {
    if (token) {
      const userProfile = await api.get<IAuthResponse<IUser>>('userInfo/getProfile', undefined, token)
      setAuthProfile(userProfile.data?.value.profile)
      setIsProfileSuccess(userProfile.success)
      setProfileErrorMessage(userProfile.success ? undefined : userProfile.message)
      // a delay to store profile in session storage before using it
      setTimeout(() => {
        setUser(userProfile.data?.value.profile)
        setIsProfileLoading(false)
      },3000)
    }
    },[token])

  useEffect(() => {
    setIsProfileLoading(true)
      if (isAuthenticated) {
        setToken(authState.accessToken?.accessToken)
        setAuthToken(authState.accessToken?.accessToken)
        getProfile()
      }
    },[isAuthenticated, authState, getProfile])

    if (isAuthenticationPending) {
      return (

          <ConnectionLoading />

      );
    }

    if (!isAuthenticated) {
      login();

      return (
        <div>
          <ConnectionLoading />
        </div>
      );
  }

  if (!user && !token) {
    return (
      <div>
        <ConnectionLoading />
      </div>);
  }

  if (isProfileLoading) {
    return (
      <div>
        <ConnectionLoading />
      </div>
    )
  } else {
    return (
      !isPorfileSuccess ? (
        <Navigate to={"/error"} state={{message: profileErrorMessage }} />
      ) : token && user && isPorfileSuccess && (
          <Shell>
            <Outlet />
        </Shell>
      )
    )}
  }


const ConnectionLoading = () => {
  return (
      <div style={{
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            gap: 8
          }}>
          <CircularProgress style={{ color: "white"}} />
          <Typography style={{color: "white"}}>Connection Loading ... </Typography>
      </div>
    )
}
