import React, { Component } from 'react'
import { MuiProps, muiOptions, defaultStyles } from '../../../materialUiThemeProvider'
import { observer } from 'mobx-react'
import { makeAutoObservable, observable, observe } from 'mobx'
import filtersState from '../../filters/filterGlobalState'
import { withStyles, createStyles } from '@material-ui/core/styles'
import jQuery from 'jquery'
import { AdvancedShippingNoticeView } from '../../../../advancedShippingNotice/models'
import { FilterAsn, OrdreDeFabrication } from '../../../../ordreDeFabrication/models'
import asnGlobalFiltersState from '../asnGlobalFiltersState'
import Guid from 'guid'
import moment from 'moment'
import FilterSelector from '../../filters/components/filterSelector'
import { AsnFilterTypes, AsnFilters } from '../models'
import asnFilterHelper from '../asnFilterHelper'
import * as Icons from '@material-ui/icons'
import { Paper, IconButton } from '@material-ui/core'
import FilterDatePicker from '../../filters/components/filterDatePicker'
import Accumulator from '../../filters/components/accumulator'
import { t } from 'i18next'
import { loadCompleteDataSetAsn } from '../asnFilterHelper'
import FilterInput from '../../filters/components/filterInput'
import filterHelper from '../../filters/filterHelper'
import { state as filterState } from '../../filters/components/filters'

class AsnFiltersState {
    @observable numberOfAppliedFilterTypes: number = 0
    @observable changed: boolean = false
    @observable appliedFilters: { [type: string]: { [keys: string]: number } } = {}

    constructor() {
        makeAutoObservable(this)
    }
}

let state = new AsnFiltersState()

@observer
class AsnAdditionalFilters extends Component<MuiProps, {}> {
    filteredOrdreDeFabrications = observe(filtersState, 'ordreDeFabricationsToDisplay', _ => {
        asnFilterHelper.initFilters(asnGlobalFiltersState.advancedShippingNoticeInitial, new AsnFilters())
        if (filtersState.ordreDeFabricationsToDisplay.length > 0) this.applyFilters()
    })

    asnObserver = observe(asnGlobalFiltersState, 'advancedShippingNoticeInitial', _ => {
        asnFilterHelper.initFilters(asnGlobalFiltersState.advancedShippingNoticeInitial, new AsnFilters())
        this.applyFilters()
    })

    handleResetAsnFilters = observe(asnGlobalFiltersState, 'filtersShouldBeReset', _ => {
        if (asnGlobalFiltersState.filtersShouldBeReset) {
            asnGlobalFiltersState.filtersData = asnFilterHelper.initAsnFilters(asnGlobalFiltersState.filtersData)
            state.appliedFilters = {}
            asnFilterHelper.initFilters(asnGlobalFiltersState.advancedShippingNoticeTotal, new AsnFilters())
            this.applyFilters()
            asnGlobalFiltersState.filtersShouldBeReset = false
        }
    })

    componentDidcomount() {
        // loadCompleteDataSetAsn()
    }

    componentWillUnmount() {
        this.filteredOrdreDeFabrications()
        this.asnObserver()
    }

    async applyFilters() {
        let result = filterHelper.filtersFlat(filterState.appliedFilters)
        let resultAsn = filterHelper.filtersFlat(state.appliedFilters)
        let body: FilterAsn = {
            ManufacturerCode: [],
            ShopCode: [],
            NumberWo: [],
            SeasonCode: [],
            TypeWO: [],
            Group: [],
            StatusWo: [],
            SubActivityCode: [],
            TypeCode: [],
            Gender: [],
            ModelCode: [],
            MaterialCode: [],
            ColorCode: [],
            Status: [],
            NumberAsn: [],
            ShippingDateFrom: '',
            ShippingDateTo: '',
        }
        result.forEach(x => {
            switch (x.type) {
                case 'color':
                    body.ColorCode.push(x.key)
                    break
                case 'manufacturer':
                    body.ManufacturerCode.push(x.key)
                    break
                case 'shop':
                    body.ShopCode.push(x.key)
                    break
                case 'season':
                    body.SeasonCode.push(x.key)
                    break
                case 'typeWO':
                    body.TypeWO.push(x.key)
                    break
                case 'group':
                    body.Group.push(x.key)
                    break
                case 'status':
                    body.StatusWo.push(x.key)
                    break
                case '#WO':
                    body.NumberWo.push(x.key)
                    break
                case 'subActivity':
                    body.SubActivityCode.push(x.key)
                    break
                case 'type':
                    body.TypeCode.push(x.key)
                    break
                case 'gender':
                    body.Gender.push(x.key)
                    break
                case 'model':
                    body.ModelCode.push(x.key)
                    break
                case 'material':
                    body.MaterialCode.push(x.key)
                    break
            }
        })
        resultAsn.forEach(x => {
            switch (x.type) {
                case 'Status':
                    if (x.key === 'Not Received' || x.key === 'Non reçu' || x.key === 'Non ricevuto') x.key = 'NonRecu'
                    else if (
                        x.key === 'Completely Received' ||
                        x.key === 'Complètement reçu' ||
                        x.key === 'Completamente ricevuto'
                    )
                        x.key = 'CompletementRecu'
                    else if (
                        x.key === 'In progress' ||
                        x.key === 'En cours' ||
                        x.key === 'In corso'
                    )
                        x.key = 'EnCours'
                    else {
                        x.key = 'PartiellementRecu'
                    }
                    body.Status.push(x.key)
                    break

                case 'NumberAsn':
                    body.NumberAsn.push(x.key)
                    break

                case 'ShippingDateFrom':
                    body.ShippingDateFrom = x.key
                    break

                case 'ShippingDateTo':
                    body.ShippingDateTo = x.key
            }
        })

        let re = await filterHelper.getFilteredAsn(body)
        asnGlobalFiltersState.advancedShippingNoticeToDisplay = re
    }

    applyGlobalOfFiltersToAsn() {
        let selectOnlyFilteredOf = (ofs: OrdreDeFabrication[]) =>
            ofs.filter(of => filtersState.ordreDeFabricationsToDisplay.some(x => x.numero == of.numero))

        let asnContainsAtLeastOneOfNotFiltered = (asn: AdvancedShippingNoticeView) => {
            var ofs = asn.ordreDeFabrications
            ofs = selectOnlyFilteredOf(ofs)
            return ofs.length > 0
        }

        let selectOnlyFilteredProducts = (refProduitIds: Guid[]) => {
            let hasAnyReferenceProduitLinkedToOfToDisplay = (id: Guid): boolean =>
                filtersState.ordreDeFabricationsToDisplay.some(x => x.referenceProduits.some(y => y.id == id))

            return refProduitIds.filter(x => true).length > 0
        }

        let asnContainsAtLeastOneReferenceProduitNotFiltered = (asn: AdvancedShippingNoticeView) => {
            return selectOnlyFilteredProducts(asn.referenceProduitIds)
        }

        asnGlobalFiltersState.advancedShippingNoticeTotal = asnGlobalFiltersState.advancedShippingNoticeInitial
            // .filter(asnContainsAtLeastOneOfNotFiltered)
            //.filter(asnContainsAtLeastOneReferenceProduitNotFiltered)
            .sort((a, b) => b.numero - a.numero)
    }

    handleChangeFilter = (type: AsnFilterTypes, key: string) => {
        let filters = jQuery.extend({}, state.appliedFilters)

        let createNewFilterType = () => {
            filters[type] = {}
            filters[type][key] = state.numberOfAppliedFilterTypes
            state.numberOfAppliedFilterTypes++
        }
        let addKeyToFilter = () => (filters[type][key] = state.numberOfAppliedFilterTypes)
        let deleteKeyFromFilter = () => {
            delete filters[type][key]
            if (Object.keys(filters[type]).length == 0) {
                delete filters[type]
                state.numberOfAppliedFilterTypes--
            }
        }

        if (type == AsnFilterTypes.dateExpeditionInf || type == AsnFilterTypes.dateExpeditionSup)
            this.handleChangeFilterDate(type, key)
        else {
            // if (type == AsnFilterTypes.numeroAsn) key = key.pad(8)
            let filterExistOnIndex = !!state.appliedFilters[type]

            if (!filterExistOnIndex) createNewFilterType()
            else {
                let keyExistOnFilter = state.appliedFilters[type][key] != undefined

                if (!keyExistOnFilter) addKeyToFilter()
                else deleteKeyFromFilter()
            }
            state.appliedFilters = filters
            state.changed = true
        }

        this.applyFilters()
    }

    handleChangeFilterDate(type: AsnFilterTypes, key: string) {
        let filters = jQuery.extend({}, state.appliedFilters)

        let createNewFilterDateType = () => {
            let newKeyDate: string =
                type == AsnFilterTypes.dateExpeditionInf ? t('date.dateFrom').concat(key) : t('date.dateTo').concat(key)
            filters[type] = {}
            filters[type][newKeyDate] = state.numberOfAppliedFilterTypes
            state.numberOfAppliedFilterTypes++
        }
        let updateDateFromFilter = () => {
            deleteDateFromFilter()
            createNewFilterDateType()
        }
        let deleteDateFromFilter = () => {
            delete filters[type]
            state.numberOfAppliedFilterTypes--
        }

        let filterExistOnIndex = !!filters[type]

        if (!filterExistOnIndex) createNewFilterDateType()
        else {
            let keyExistOnFilter = Object.keys(filters[type])[0] == key

            if (!keyExistOnFilter) updateDateFromFilter()
            else deleteDateFromFilter()
        }

        state.appliedFilters = filters
    }

    handleReset = async () => {
        asnGlobalFiltersState.filtersShouldBeReset = true
    }

    render() {
        if (!asnGlobalFiltersState.filtersShouldBeDisplayed) return null

        let filtersData = asnGlobalFiltersState.filtersData

        let classes = this.props.classes
        let filters = state.appliedFilters
        let dateFrom =
            !!state.appliedFilters[AsnFilterTypes.dateExpeditionInf] &&
            Object.keys(state.appliedFilters[AsnFilterTypes.dateExpeditionInf])[0].replace(t('date.dateFrom'), '')
        let dateTo =
            !!state.appliedFilters[AsnFilterTypes.dateExpeditionSup] &&
            Object.keys(state.appliedFilters[AsnFilterTypes.dateExpeditionSup])[0].replace(t('date.dateTo'), '')

        let statutFilterAsn: { [key: string]: string } = {}
        let statuts = ['nonRecu', 'partiellementRecu', 'completementRecu','enCours']
        for (let i = 0; i < statuts.length; i++) {
            let key = t('asn.statut.'.concat(statuts[i].toLowerFirstChar()))
            statutFilterAsn[key] = key
        }

        let fabricantFilterAsn: { [key: string]: string } = {}
        let fabricants = Object.keys(filtersData.fabricant)
        for (let i = 0; i < fabricants.length; i++) {
            let key = t(fabricants[i])
            fabricantFilterAsn[key] = key
        }
        return (
            <Paper className={classes.filterZone}>
                <div>
                    <Accumulator
                        handleDeleteFilter={this.handleChangeFilter.bind(this)}
                        filtersAccumulated={asnFilterHelper.filtersFlat(state.appliedFilters)}
                    />
                </div>
                <div className={classes.filtersContainer}>
                    <IconButton
                        color="primary"
                        className={classes.button}
                        aria-label="Reset"
                        onClick={async () => await this.handleReset()}
                        style={{ alignSelf: 'center' }}>
                        <Icons.SettingsBackupRestore />
                    </IconButton>

                    <FilterInput
                        handleChange={this.handleChangeFilter.bind(this)}
                        type={AsnFilterTypes.numeroAsn}
                        parse={true}
                    />
                    <FilterSelector
                        handleChange={this.handleChangeFilter.bind(this)}
                        type={AsnFilterTypes.statut}
                        typeValues={statutFilterAsn}
                        selectedItems={!!filters[AsnFilterTypes.statut] && Object.keys(filters[AsnFilterTypes.statut])}
                    />
                    <FilterDatePicker
                        type={AsnFilterTypes.dateExpeditionInf}
                        date={!!dateFrom ? moment(dateFrom).toDate() : filtersData.dateExpeditionInf.key}
                        handleChange={this.handleChangeFilter.bind(this)}
                    />
                    <FilterDatePicker
                        type={AsnFilterTypes.dateExpeditionSup}
                        date={!!dateTo ? moment(dateTo).toDate() : filtersData.dateExpeditionSup.key}
                        handleChange={this.handleChangeFilter.bind(this)}
                    />
                </div>
            </Paper>
        )
    }
}

let styles = () =>
    createStyles({
        filtersContainer: {
            ...defaultStyles.flexRow,
            alignItems: 'flex-end',
            padding: '0.5%',
        },
    })

export default withStyles(styles, muiOptions)(AsnAdditionalFilters)

export { state }
