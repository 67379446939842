import { t } from 'i18next'
import filtersState from './filterGlobalState'
import api from '../../api'
import {
    Couleur,
    FilterAsn,
    Filters,
    FiltersPost,
    OrdreDeFabrication,
    OrdreDeFabricationFiltered,
    ReferenceProduit,
} from '../../../ordreDeFabrication/models'
import { OrdreDeFabricationFilters, FilterType } from './models'
import { state, WoRequest, WoReceivedStatus } from './components/filters'
import authToken, { getAuthProfile } from '../../authToken'
import toolbarState from '../toolbar'
import { AdvancedShippingNoticeView, AdvancedShippingNoticeViewFiltered } from '../../../advancedShippingNotice/models'
import asnGlobalFiltersState from '../asnFilters/asnGlobalFiltersState'
import ofStatutChip from '../../../common/ofStatutChip'
import { runInAction } from 'mobx'
let woPromise: Promise<OrdreDeFabrication[]>

const WoRequestToString = (woRequest: WoRequest) => {
    switch (woRequest) {
        case WoRequest.OnlyClosed:
            return 'onlyClosed'
        case WoRequest.OnlyOpened:
            return 'onlyOpened'
        default:
            return ''
    }
}

const getFilters = async () => {
    const wantedWo = state.hideClosedWo ? WoRequest.OnlyOpened : WoRequest.OnlyClosed
    const filters = await api.get<Filters>(`ofProduit/filters/${WoRequestToString(wantedWo)}`)
    filtersState.filters = filters
}

const getFilteredWos = async (woRequest: WoRequest, body: any, number: any, signal?: AbortSignal): Promise<OrdreDeFabricationFiltered> => {
    if (body != null) {
        let ofs = await api.post<FiltersPost>(`ofProduit/filters/${number}/${WoRequestToString(woRequest)}`, {
            ColorCode: body.ColorCode,
            MaterialCode: body.MaterialCode,
            ModelCode: body.ModelCode,
            TypeCode: body.TypeCode,
            ManufacturerCode: body.ManufacturerCode,
            ShopCode: body.ShopCode,
            SeasonCode: body.SeasonCode,
            TypeWO: body.TypeWO,
            Group: body.Group,
            Status: body.Status,
            Number: body.Number,
            SubActivityCode: body.SubActivityCode,
            Gender: body.Gender,
        } as FiltersPost, signal)
        filtersState.filters = ofs.filters
        return ofs
    } else {
        let ofs = await api.post<FiltersPost>(`ofProduit/filters/1/${WoRequestToString(woRequest)}`, {} as FiltersPost,signal)
        filtersState.filters = ofs.filters
        return ofs
    }
}
const getFilteredAsn = async (body: FilterAsn, pageNumber: number = 1): Promise<AdvancedShippingNoticeView[]> => {
    let asnDisplay = asnGlobalFiltersState.advancedShippingNoticeInitial as AdvancedShippingNoticeView[]
    const userProfile = authToken.getAuthProfile()
    let fabricant: any
    if (userProfile) fabricant = userProfile.fabricantCode
    if (!fabricant || fabricant == 'undefined') fabricant = 'none'
    if (
        body.ManufacturerCode.length !== 0 ||
        body.ShopCode.length !== 0 ||
        body.NumberAsn.length !== 0 ||
        body.SeasonCode.length !== 0 ||
        body.TypeWO.length !== 0 ||
        body.StatusWo.length !== 0 ||
        body.Group.length !== 0 ||
        body.SubActivityCode.length !== 0 ||
        body.TypeCode.length !== 0 ||
        body.Gender.length !== 0 ||
        body.ModelCode.length !== 0 ||
        body.MaterialCode.length !== 0 ||
        body.ColorCode.length !== 0 ||
        body.NumberWo.length !== 0 ||
        body.Status.length !== 0 ||
        body.ShippingDateFrom !== '' ||
        body.ShippingDateTo !== ''
    ) {
        let asn = await api.post<any>(`asn/filters/${pageNumber}/${fabricant}`, {
            ManufacturerCode: body.ManufacturerCode,
            ShopCode: body.ShopCode,
            Status: body.Status,
            Number: body.NumberAsn,
            NumberWo: body.NumberWo,
            SeasonCode: body.SeasonCode,
            TypeWO: body.TypeWO,
            Group: body.Group,
            StatusWo: body.StatusWo,
            TypeCode: body.TypeCode,
            Gender: body.Gender,
            MaterialCode: body.MaterialCode,
            ModelCode: body.ModelCode,
            ColorCode: body.ColorCode,
            SubActivityCode: body.SubActivityCode,
            ShippingDateFrom: body.ShippingDateFrom,
            ShippingDateTo: body.ShippingDateTo,
        })
        runInAction(() => (asnGlobalFiltersState.advancedShippingNoticeCount = asn.total))

        asnDisplay = asn.asn

        return asnDisplay
    } else {
        let asn = await api.post<any>(`asn/filters/${pageNumber}/${fabricant}`, {})
        runInAction(() => (asnGlobalFiltersState.advancedShippingNoticeCount = asn.total))
        asnDisplay = asn.asn
        return asnDisplay
    }
}
const getWo = async (woRequest: WoRequest) =>
    await api.get<OrdreDeFabrication[]>(`ofProduit/all/${WoRequestToString(woRequest)}`)

const getFabricantWo = async (
    fabricantCode: string,
    woRequest: WoRequest,
    body: any,
    number: any,
    signal?: AbortSignal
): Promise<OrdreDeFabricationFiltered> => {
    if (body != null) {
        let ofs = await api.post<FiltersPost>(
            `ofProduit/allAsFabricant/${fabricantCode}/${number}/${WoRequestToString(woRequest)}`,
            {
                ColorCode: body.ColorCode,
                MaterialCode: body.MaterialCode,
                ModelCode: body.ModelCode,
                TypeCode: body.TypeCode,
                ManufacturerCode: body.ManufacturerCode,
                ShopCode: body.ShopCode,
                SeasonCode: body.SeasonCode,
                TypeWO: body.TypeWO,
                Group: body.Group,
                Status: body.Status,
                Number: body.Number,
                SubActivityCode: body.SubActivityCode,
                Gender: body.Gender,
            } as FiltersPost,
            signal
        )
        filtersState.filters = ofs.filters
        return ofs
    } else {
        let ofs = await api.post<FiltersPost>(
            `ofProduit/allAsFabricant/${fabricantCode}/1/${WoRequestToString(woRequest)}`,
            {} as FiltersPost,
            signal
        )
        filtersState.filters = ofs.filters
        return ofs
    }
}
const isDataAlreadyLoaded = (): boolean =>
    state.woReceived == WoReceivedStatus.All || (state.hideClosedWo && state.woReceived == WoReceivedStatus.OnlyOpened)

const setWoDataset = async (woPromise: Promise<OrdreDeFabricationFiltered>): Promise<OrdreDeFabrication[]> => {
    runInAction(async () => (filtersState.ordreDeFabricationsCount = (await woPromise).total))

    return runInAction(async () => (filtersState.ordreDeFabricationsTotal = (await woPromise).ofs))
}

const addWoDataset = async (woPromise: Promise<OrdreDeFabricationFiltered>): Promise<OrdreDeFabrication[]> => {
    const value = await woPromise

    runInAction(() => (filtersState.ordreDeFabricationsCount = value.total))
    return (filtersState.ordreDeFabricationsTotal =
        state.woReceived == WoReceivedStatus.OnlyOpened && !state.hideClosedWo
            ? [...filtersState.ordreDeFabricationsTotal, ...value.ofs]
            : value.ofs)
}
// S'assure que les OF voulus soient chargés
const loadWantedWo = async (body: any = null, number: any = 1) => {
    // Force un rechargement des OF si 10 minutes sont passées.
    if (new Date().getTime() - state.lastUpdateWo > 10 * 60 * 1000) {
        await reloadWantedWo(body)
        return
    }
    // Ne pas recharger les OF s'ils sont déjà chargés
    //if (isDataAlreadyLoaded()) return
    // Bloque les chargements d'OF simultanés en annulant les anciennes requetes
    if (state.loadWoIsRunning) {
        state.abortController.abort("Request aborted because of a new one");
        state.abortController = new AbortController();
    }
    const signal = state.abortController.signal;
    // Demande uniquement les OF voulus au serveur, et les ajoute aux OF déjà obtenus (OF opened + OF closed)
    const userProfile = authToken.getAuthProfile()
    if (!userProfile) return
    try {
        runInAction(() => (state.loadWoIsRunning = true))
        const wantedWo = state.hideClosedWo ? WoRequest.OnlyOpened : WoRequest.OnlyClosed
        woPromise = addWoDataset(
            userProfile.group == authToken.GroupTypes.fabricant
                ? getFabricantWo(userProfile.fabricantCode, wantedWo, body, number,signal)
                : getFilteredWos(wantedWo, body, number, signal),
        )
        await woPromise
    } finally {
        runInAction(() => {
            state.loadWoIsRunning = signal.aborted
            state.woReceived = state.hideClosedWo ? WoReceivedStatus.OnlyOpened : WoReceivedStatus.All
        })
    }
}

// Force un rechargement des OF voulus
const reloadWantedWo = async (body: any = null, number: any = 1) => {
    // Bloque les chargements d'OF simultanés (en mettant en attente les doublons)
    if (state.loadWoIsRunning) await woPromise
    else {
        // Demande uniquement les OF voulus au serveur
        const userProfile = getAuthProfile()
        if (!userProfile) return
        try {
            runInAction(() => (state.loadWoIsRunning = true))
            const wantedWo = state.hideClosedWo ? WoRequest.OnlyOpened : WoRequest.All
            woPromise = setWoDataset(
                userProfile.group == authToken.GroupTypes.fabricant
                    ? getFabricantWo(userProfile.fabricantCode, wantedWo, body, number)
                    : getFilteredWos(wantedWo, body, number),
            )
            await woPromise
        } finally {
            runInAction(() => (state.loadWoIsRunning = false))
            state.woReceived = state.hideClosedWo ? WoReceivedStatus.OnlyOpened : WoReceivedStatus.All
            state.lastUpdateWo = new Date().getTime()
        }
    }
}

const reloadReferenceProduit = async (ofNumero: string, referenceProduitId: string) => {
    const newReferenceProduit = await api.get<ReferenceProduit>(
        'ofProduit/planificationProduit/'.concat(referenceProduitId),
    )
    const referenceProduitToChange = filtersState.ordreDeFabricationsTotal
        .first(x => x.numero == ofNumero)
        .referenceProduits.first(x => x.id == referenceProduitId)
    referenceProduitToChange.celineValidatedInitial = newReferenceProduit.celineValidatedInitial
    referenceProduitToChange.celineValidatedInitialAt = newReferenceProduit.celineValidatedInitialAt
    referenceProduitToChange.fabricantValidatedInitial = newReferenceProduit.fabricantValidatedInitial
    referenceProduitToChange.fabricantValidatedInitialAt = newReferenceProduit.fabricantValidatedInitialAt
    referenceProduitToChange.quantiteSemaines = newReferenceProduit.quantiteSemaines
    referenceProduitToChange.quantiteStatuts = newReferenceProduit.quantiteStatuts
    referenceProduitToChange.quantiteTailleStatuts = newReferenceProduit.quantiteTailleStatuts
    referenceProduitToChange.statut = newReferenceProduit.statut
}

const emptyAllExcept = (filters: OrdreDeFabricationFilters, type: FilterType) => {
    init(filters, type)
}

const getPropertyNameFromFilterType = (type: FilterType) => {
    switch (type) {
        case FilterType.couleur:
            return 'couleur'
        case FilterType.fabricant:
            return 'fabricant'
        case FilterType.magasin:
            return 'magasin'
        case FilterType.groupage:
            return 'groupage'
        case FilterType.matiere:
            return 'material'
        case FilterType.modele:
            return 'modele'
        case FilterType.numeroOrdreDeFabrication:
            return 'numeroOrdreDeFabrication'
        case FilterType.saison:
            return 'saison'
        case FilterType.sousActivite:
            return 'sousActivite'
        case FilterType.statut:
            return 'statut'
        case FilterType.typeOrdreDeFabrication:
            return 'typeWO'
        case FilterType.type:
            return 'type'
        case FilterType.genre:
            return 'genre'
    }
}

const propFabricant = (): string => getPropertyNameFromFilterType(FilterType.fabricant)
const propMagasin = (): string => getPropertyNameFromFilterType(FilterType.magasin)
const propGroupage = (): string => getPropertyNameFromFilterType(FilterType.groupage)
const propNumeroOf = (): string => getPropertyNameFromFilterType(FilterType.numeroOrdreDeFabrication)
const propSaison = (): string => getPropertyNameFromFilterType(FilterType.saison)
const propTypeWO = (): string => getPropertyNameFromFilterType(FilterType.typeOrdreDeFabrication)
const propStatut = (): string => getPropertyNameFromFilterType(FilterType.statut)
const propCouleur = (): string => getPropertyNameFromFilterType(FilterType.couleur)
const propMatiere = (): string => getPropertyNameFromFilterType(FilterType.matiere)
const propModele = (): string => getPropertyNameFromFilterType(FilterType.modele)
const propType = (): string => getPropertyNameFromFilterType(FilterType.type)
const propGenre = (): string => getPropertyNameFromFilterType(FilterType.genre)
const propSousActivite = (): string => getPropertyNameFromFilterType(FilterType.sousActivite)

const init = (filters: OrdreDeFabricationFilters, typeToIgnore?: FilterType) => {
    const propToIgnore = !!typeToIgnore ? getPropertyNameFromFilterType(typeToIgnore) : ''
    if (propToIgnore != propSousActivite()) filters.sousActivite = {}
    if (propToIgnore != propSaison()) filters.saison = {}
    if (propToIgnore != propFabricant()) filters.fabricant = {}
    if (propToIgnore != propMagasin()) filters.magasin = {}
    if (propToIgnore != propTypeWO()) filters.typeWO = {}
    if (propToIgnore != propStatut()) filters.statut = {}
    if (propToIgnore != propGroupage()) filters.groupage = {}
    if (propToIgnore != propNumeroOf()) filters.numeroOrdreDeFabrication = {}
    if (propToIgnore != propModele()) filters.modele = {}
    if (propToIgnore != propMatiere()) filters.material = {}
    if (propToIgnore != propCouleur()) filters.couleur = {}
    if (propToIgnore != propType()) filters.type = {}
    if (propToIgnore != propGenre()) filters.genre = {}
}

const getReferenceProduitNb = (woList: OrdreDeFabrication[]) =>
    woList.reduce((acc, current) => acc + current.referenceProduits.length, 0)

const filterOpenedWo = (woList: OrdreDeFabrication[]) => {
    if (!state.hideClosedWo) return woList
    const filteredWoList = woList
    filteredWoList.forEach(wo => {
        wo.referenceProduits = wo.referenceProduits.filter(refProduit => refProduit.statut != 'Closed')
    })
    return filteredWoList.filter(wo => wo.referenceProduits.length > 0)
}

const resetFilters = () => {
    runInAction(() => (filtersState.filtersData = new OrdreDeFabricationFilters()))
}

const updateFilters = (filters: Filters) => {
    const filterData = filtersState.filtersData

    const filterFields: { [field: string]: { [code: string]: string } } = {}

    for (const typeFiltre in filterData) filterFields[typeFiltre] = filterData[typeFiltre]

    if (filters) {
        if (filters.manufacturer)
            filters.manufacturer.forEach(filter => {
                if (!!filter)
                    if (!(filter.code in filterFields[propFabricant()]))
                        filterFields[propFabricant()][filter.code] = filter.label
            })
        if (filters.shop)
            filters.shop.forEach(filter => {
                if (!!filter)
                    if (!(filter.code in filterFields[propMagasin()]))
                        filterFields[propMagasin()][filter.code] = filter.label
            })
        if (filters.number)
            filters.number.forEach(filter => {
                if (!!filter)
                    if (!(filter in filterFields[propNumeroOf()])) filterFields[propNumeroOf()][filter] = filter
            })

        if (filters.season)
            filters.season.forEach(filter => {
                if (!!filter)
                    if (!(filter.code in filterFields[propSaison()]))
                        filterFields[propSaison()][filter.code] = filter.description
            })

        if (filters.typeWO)
            filters.typeWO.forEach(type => {
                if (!(type in filterFields[propTypeWO()]))
                    filterFields[propTypeWO()][type] = type ? t('typeOf.'.concat(type.toLowerCase())) : type
            })
        if (filters.group)
            filters.group.forEach(group => {
                if (!(group in filterFields[propGroupage()])) filterFields[propGroupage()][group] = group
            })

        if (filters.status)
            filters.status.forEach(status => {
                if (!status) return
                if (!(status in filterFields[propStatut()]))
                    filterFields[propStatut()][status] = status ? t('statutOf.'.concat(status.toLowerCase())) : status
            })

        if (filters.gender)
            filters.gender.forEach(gender => {
                if (!gender) return
                if (!(gender in filterFields[propGenre()])) filterFields[propGenre()][gender] = gender
            })

        if (filters.material)
            filters.material.forEach(filter => {
                if (!!filter)
                    if (!(filter.code in filterFields[propMatiere()]))
                        filterFields[propMatiere()][filter.code] = filter.code.concat(' - ' + filter.description)
            })

        if (filters.model)
            filters.model.forEach(filter => {
                if (!!filter)
                    if (!(filter.code in filterFields[propModele()]))
                        filterFields[propModele()][filter.code] = filter.code.concat(' - ' + filter.description)
            })
        if (filters.color)
            filters.color.forEach(filter => {
                if (!!filter)
                    if (!(filter.code in filterFields[propCouleur()]))
                        filterFields[propCouleur()][filter.code] = filter.code.concat(' - ' + filter.description)
            })

        if (filters.subActivity)
            filters.subActivity.forEach(filter => {
                if (!!filter)
                    if (!(filter.code in filterFields[propSousActivite()]))
                        filterFields[propSousActivite()][filter.code] = filter.code.concat(
                            filter.description ? ' - ' + filter.description : '',
                        )
            })

        if (filters.type)
            filters.type.forEach(filter => {
                if (!!filter)
                    if (!(filter.code in filterFields[propSousActivite()]))
                        filterFields[propType()][filter.code] = filter.code.concat(
                            filter.description ? ' - ' + filter.description : '',
                        )
            })

        for (let i in filterFields) {
            let field: { [code: string]: string } = filterFields[i]
            filterData[i] = field
        }

        runInAction(() => (filtersState.filtersData = filterData))
    }
}

const filtersFlat = (filters: { [type: string]: { [keys: string]: number } }): { type: string; key: string }[] => {
    const types = Object.keys(filters)

    const result: { type: string; key: string }[] = []
    types.forEach(x => Object.keys(filters[x]).forEach(k => result.push({ type: x, key: k })))

    return result
}

const getFiltersOfType = (type: FilterType): string[] => {
    const resultFilters = state.appliedFilters[type]
    if (!resultFilters) return []
    return Object.keys(resultFilters)
}

const setFilterVisibility = (value: boolean) => {
    filtersState.filtersShouldBeDisplayed = value
}

const getLabelValue = (type: string, key: string) => {
    const prop = getPropertyNameFromFilterType(type as FilterType)

    const result = filtersState.filtersData[prop][key]
    return !!result ? result : key
}

export default {
    emptyAllExcept,
    init,
    getReferenceProduitNb,
    filterOpenedWo,
    resetFilters,
    updateFilters,
    filtersFlat,
    loadWantedWo,
    reloadWantedWo,
    reloadReferenceProduit,
    getFiltersOfType,
    setFilterVisibility,
    getLabelValue,
    getFilters,
    getFilteredAsn,
    getWo,
}
