import { Profile } from './signIn/models'

export function setAuthTokenAndProfile(authToken?: string, profile?: Profile): void {
    sessionStorage.setItem('authToken', authToken ?? "")
    sessionStorage.setItem('profile', JSON.stringify(profile))
}

export function setAuthProfile(profile?: Profile): void {
    sessionStorage.setItem('profile', JSON.stringify(profile))
}

export function setAuthToken(authToken?: string): void {
    sessionStorage.setItem('authToken', authToken ?? "")
}

function removeAuthTokenAndProfile(): void {
    sessionStorage.clear()
}

export function getAuthToken(): string | null {
    return sessionStorage.getItem('authToken')
}

export function getAuthProfile(): Profile | null {
    let profile = sessionStorage.getItem('profile')
    if (profile != null) {
        return JSON.parse(profile) as Profile
    } else {
        return null
    }
}

export enum GroupTypes {
    fabricant = 'RTW_Supplier_Portal_Suppliers',
    celine = 'RTW_Supplier_Portal_Celine_Prod',
    admin = 'RTW_Supplier_Portal_Admin',
    readOnly = 'RTW_Supplier_Portal_Read_Only',
}

export default { setAuthTokenAndProfile, removeAuthTokenAndProfile, getAuthToken, getAuthProfile, GroupTypes }
