import React from 'react'
import { observer } from 'mobx-react'
import {
    Paper,
    Table,
    TableHead,
    TableRow as MuiTableRow,
    TableBody,
    TableCell as MuiTableCell,
    withStyles,
    createStyles,
} from '@material-ui/core'
import {
    MuiProps,
    muiOptions,
    customStyledComponent,
    defaultStyles,
} from '../../../infrastructure/materialUiThemeProvider'
import { ReferenceProduit, QuantiteTailleStatut, QuantiteStatut, StatutSkus } from '../../models'
import { statutSkuState } from '../../statutSku'
import { getOrderedTailles } from '../../../common/grilleTaille'
import InputUpdateQuantite from './_inputUpdateQuantite'
import { t } from 'i18next'

type DetailDynamicTableProps = {
    referenceProduit: ReferenceProduit
    isEditableCell?: boolean
    isFlexGrow?: boolean
}

function getQuantiteParTailleAndStatut(
    statutCode: string,
    taille: string,
    quantiteTailleStatuts?: QuantiteTailleStatut[],
): QuantiteTailleStatut | undefined {
    return (
        quantiteTailleStatuts && quantiteTailleStatuts.first(e => e.statutSku == statutCode && e.tailleCode == taille)
    )
}

function getQuantiteParStatut(statutCode: string, quantiteStatuts?: QuantiteStatut[]): number | null {
    let quantiteStatut = quantiteStatuts && quantiteStatuts.first(e => e.statutSku == statutCode)
    return quantiteStatut && quantiteStatut.quantite != null ? quantiteStatut.quantite : null
}

let QuantiteParTailleDynamicTable = observer(
    ({ classes, isFlexGrow, ...props }: MuiProps & DetailDynamicTableProps) => {
        let tailles: string[] = props.referenceProduit.quantiteTailleStatuts.map(m => m.tailleCode).distinct()
        let orderedTailles = getOrderedTailles(
            tailles,
            x => x?.toString()?.trim(),
            props.referenceProduit.produit.grilleTailleCode,
        )

        return (
            <Paper className={classes.root} style={isFlexGrow ? { flexGrow: 1 } : {}}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            <TableCell className={classes.headerCell}>{t('common.size')}</TableCell>
                            {orderedTailles.map((taille, index) => (
                                <TableCell key={index + ' column header'} className={classes.headerCell}>
                                    {taille}
                                </TableCell>
                            ))}
                            <TableCell className={classes.headerCell}>{t('common.total')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {statutSkuState.itemsToShowByTaille.map((statut, index) => {
                            let quantiteParStatut = getQuantiteParStatut(
                                statut.code,
                                props.referenceProduit.quantiteStatuts,
                            )
                            let isErrorQuantiteParStatut = quantiteParStatut != null && quantiteParStatut < 0

                            return (
                                <TableRow
                                    key={index + ' line'}
                                    className={index % 2 != 0 ? classes.innerTableRowEvenWeek : {}}>
                                    <TableCell className={classes.tableCell}>
                                        {t('status.'.concat(statut.code.toLowerFirstChar()))}
                                    </TableCell>
                                    {orderedTailles.map((taille, index) => {
                                        let quantiteTaille = getQuantiteParTailleAndStatut(
                                            statut.code,
                                            taille,
                                            props.referenceProduit.quantiteTailleStatuts,
                                        )
                                        let isResteAExpedierAndNotNull =
                                            !!quantiteTaille &&
                                            quantiteTaille.quantite != null &&
                                            quantiteTaille.statutSku == StatutSkus.ResteAExpedier
                                        let isNotNullOrZero = !!quantiteTaille && quantiteTaille.quantite
                                        let quantiteParTailleAndStatut =
                                            !!quantiteTaille && (isResteAExpedierAndNotNull || isNotNullOrZero)
                                                ? quantiteTaille.quantite
                                                : null
                                        let isErrorQuantiteParTailleAndStatut =
                                            quantiteParTailleAndStatut != null && quantiteParTailleAndStatut < 0

                                        return (
                                            <TableCell
                                                className={
                                                    isErrorQuantiteParTailleAndStatut
                                                        ? classes.tableCellRed
                                                        : classes.tableCell
                                                }
                                                key={index + ' column'}>
                                                {!!props.isEditableCell &&
                                                    statut.code == StatutSkus.Annule && (
                                                        <InputUpdateQuantite
                                                            referenceProduit={props.referenceProduit}
                                                            tailleCode={taille}
                                                            statutCode={statut.code}
                                                            getQuantiteParTailleAndStatut={
                                                                getQuantiteParTailleAndStatut
                                                            }
                                                        />
                                                    )}

                                                {(!props.isEditableCell || statut.code != StatutSkus.Annule) &&
                                                    (quantiteParTailleAndStatut != null
                                                        ? quantiteParTailleAndStatut.toString()
                                                        : '')}
                                            </TableCell>
                                        )
                                    })}
                                    <TableCell
                                        className={isErrorQuantiteParStatut ? classes.tableCellRed : classes.tableCell}>
                                        {quantiteParStatut != null ? quantiteParStatut.toString() : ''}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Paper>
        )
    },
)

let TableRow = customStyledComponent(MuiTableRow)(
    {
        ...defaultStyles.tableRow,
    },
    muiOptions,
)

let TableCell = customStyledComponent(MuiTableCell)(
    {
        ...defaultStyles.tableCell,
    },
    muiOptions,
)

let styles = theme =>
    createStyles({
        root: {
            margin: '10px',
            overflowX: 'auto',
            ...defaultStyles.effect3D,
        },
        table: {
            width: '100%',
        },
        headerRow: {
            ...defaultStyles.headerRow,
        },
        headerCell: {
            ...defaultStyles.headerCell,
        },
        tableCell: {
            ...defaultStyles.tableCell,
        },
        tableCellRed: {
            ...defaultStyles.tableCell,
            color: 'red',
        },
        innerTableRowEvenWeek: {
            ...defaultStyles.evenTableRow,
        },
    })

export default withStyles(styles, muiOptions)(QuantiteParTailleDynamicTable)
