import React from 'react'
import { MuiProps, muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { FormControl, Select, MenuItem, createStyles, withStyles } from '@material-ui/core'
import { t } from 'i18next'
import { statutReferenceProduitState } from '../../statutReferenceProduit'
import { StatutRefProduit } from '../../models'
import OfStatutChip from '../../../common/ofStatutChip'

type FilterSelectorProps = {
    handleChange: (ordreDeFabricationNumero: string, produitCode: string, newStatut: string) => void
    ordreDeFabricationNumero: string
    produitCode: string
    selectedItem: string
    disabled?:boolean
}

function FilterSelector({
    handleChange,
    ordreDeFabricationNumero,
    produitCode,
    selectedItem,
    classes,
    disabled
}: MuiProps & FilterSelectorProps) {
    let canChangeStatus = !disabled && selectedItem != StatutRefProduit.Alert && selectedItem != StatutRefProduit.Closed
    return (
        <FormControl className={classes.formControl}>
            <Select
                onChange={event => handleChange(ordreDeFabricationNumero, produitCode, event.target.value as string)}
                name={'status'}
                value={selectedItem}
                renderValue={value => value && t('statutOf.'.concat((value as string).toLowerFirstChar()))}
                displayEmpty
                disabled={!canChangeStatus}
                className={classes.selectEmpty}>
                <MenuItem value={''} disabled>
                    {t('product.status')}
                </MenuItem>
                {statutReferenceProduitState.items.map(x => (
                    <MenuItem value={x.code} key={x.code}>
                        <OfStatutChip
                            className={classes.chip}
                            label={t('statutOf.'.concat(x.code.toLowerFirstChar()))}
                            statut={x.code}
                            disableTooltip={true}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

let styles = () =>
    createStyles({
        formControl: {
            width: '10%',
        },
        chip: {
            cursor: 'pointer',
            width: '100%',
        },
    })

export default withStyles(styles, muiOptions)(FilterSelector)
