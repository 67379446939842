import React, { Component } from 'react'
import { observable, makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import { createStyles, withStyles } from '@material-ui/core'
import { Paper, Divider, Dialog, DialogTitle, DialogContent, CircularProgress } from '@material-ui/core'
import { MuiProps, muiOptions, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import filterHelper from '../../../infrastructure/toolbar/filters/filterHelper'
import { OrdreDeFabrication } from '../../models'
import api from '../../../infrastructure/api'
import QuantiteParTailleDynamicTable from '../liste/_quantiteParTailleDynamicTable'
import Header from './header'
import ProduitInfos from './produitInfos'
import { Commentaire, StatutRefProduit, ModifierStatutOrdreDeFabrication } from '../../models'
import Commentaires, { state as listeCommentaireState } from '../commentaires/liste'
import NewCommentaire from '../commentaires/create'
import { getAuthProfile, GroupTypes } from '../../../infrastructure/authToken'

type OrdreDeFabricationSkuDetailsProps = {
    match: {
        params: {
            numero: string
            produitCode: string
        }
    }
}

class OrdreDeFabricationSkuDetailsState {
    @observable of: OrdreDeFabrication | null = null
    @observable openSuspendedDialog: boolean = false
    isReadOnly: boolean = true

    constructor() {
        makeAutoObservable(this)
    }
}

let state = new OrdreDeFabricationSkuDetailsState()

@observer
class OrdreDeFabricationSkuDetails extends Component<MuiProps & OrdreDeFabricationSkuDetailsProps> {
   
    async componentDidMount() {
        filterHelper.setFilterVisibility(false)
        state.of = await this.loadDetailsOfSku(this.props.match.params.numero, this.props.match.params.produitCode)
        state.isReadOnly = getAuthProfile()?.group == GroupTypes.readOnly
    }

    componentDidUpdate() {
        filterHelper.setFilterVisibility(false)
    }

    componentWillUnmount() {
        filterHelper.setFilterVisibility(true)
        state.of = null
    }

    async loadDetailsOfSku(ordreDeFabricationNumero: string, produitCode: string): Promise<OrdreDeFabrication> {
        return await api.get<OrdreDeFabrication>(`ofProduit/one/${ordreDeFabricationNumero}/${produitCode}/`)
    }

    async loadComments(ordreDeFabricationNumero: string, produitCode: string) {
        listeCommentaireState.comments = await api.get<Commentaire[]>(
            `commentaire/${ordreDeFabricationNumero}/${produitCode}`,
        )
        listeCommentaireState.nbAlerts = listeCommentaireState.comments && listeCommentaireState.comments.length
        listeCommentaireState.hasAlert =
            listeCommentaireState.comments && listeCommentaireState.comments.filter(x => x.isAlert).length > 0
        listeCommentaireState.addNewComment = false
    }

    async updateStatutOf(ordreDeFabricationNumero: string, produitCode: string, newStatut: string) {
        await api.post<ModifierStatutOrdreDeFabrication>('ofProduit/modifierStatut', {
            OrdreDeFabricationNumero: ordreDeFabricationNumero,
            ProduitCode: produitCode,
            Statut: newStatut,
        })
    }

    async validationSuspendedStatut(ordreDeFabricationNumero: string, produitCode: string) {
        state.openSuspendedDialog = false

        await this.updateStatutOf(ordreDeFabricationNumero, produitCode, StatutRefProduit.Suspended.valueOf())

        state.of = await this.loadDetailsOfSku(ordreDeFabricationNumero, produitCode)

        await this.loadComments(ordreDeFabricationNumero, produitCode)
    }

    async cancelSuspendedDialog() {
        state.openSuspendedDialog = false
    }

    async handleChangeStatut(ordreDeFabricationNumero: string, produitCode: string, newStatut: string) {
        let refProduit = !!state.of ? state.of.referenceProduits.first() : null
        let valueChanged = !!refProduit && newStatut != refProduit.statut
        if (valueChanged) {
            if (newStatut == StatutRefProduit.Suspended) {
                state.openSuspendedDialog = true
            } else {
                await this.updateStatutOf(ordreDeFabricationNumero, produitCode, newStatut)
                state.of = await this.loadDetailsOfSku(ordreDeFabricationNumero, produitCode)
            }
        }
    }

    render() {
        let { classes } = this.props
        let refProduit = !!state.of ? state.of.referenceProduits.first() : null
        let produit = !!state.of && !!state.of.referenceProduits ? state.of.referenceProduits.first().produit : null
        return !(state.of && refProduit && produit) ? (
            <CircularProgress className={classes.spinner} size={20} />
        ) : (
            <Paper className={classes.root}>
                <Header
                    of={state.of}
                    referenceProduit={refProduit}
                    handleChangeStatut={this.handleChangeStatut.bind(this)}
                    isReadOnly = {state.isReadOnly}
                />
                <Divider />
                <div className={classes.body}>
                    <ProduitInfos produit={produit} />
                    <div className={classes.table}>
                        <QuantiteParTailleDynamicTable referenceProduit={refProduit} isEditableCell={!state.isReadOnly} />
                        <Commentaires
                            ordreDeFabricationNumero={refProduit.ordreDeFabricationNumero}
                            produitCode={refProduit.produitCode}
                            loadComments={this.loadComments}
                            isReadOnly={state.isReadOnly}
                        />
                    </div>
                </div>
                <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="sm" open={state.openSuspendedDialog}>
                    <DialogTitle>{t('detailsOf.commentaires.labelSuspensionMessage')}</DialogTitle>
                    <DialogContent>
                       <NewCommentaire
                            ordreDeFabricationNumero={refProduit.ordreDeFabricationNumero}
                            produitCode={refProduit.produitCode}
                            loadComments={this.validationSuspendedStatut.bind(this)}
                            cancelAddNewComment={this.cancelSuspendedDialog}
                            isSuspension={true}
                        />
                    </DialogContent>
                </Dialog>
            </Paper>
        )
    }
}

let styles = () =>
    createStyles({
        root: {
            ...defaultStyles.flexColumn,
            marginTop: -30,
        },
        body: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
        },
        table: {
            ...defaultStyles.flexColumn,
            width: '65%',
        },
        spinner: {
            ...defaultStyles.spinner,
        },
    })

export default withStyles(styles, muiOptions)(OrdreDeFabricationSkuDetails)
